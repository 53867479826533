import React, { Component, Fragment } from 'react'

import ActiveThumbnailWindow from './ActiveThumbnailWindow'
import ThumbnailGrid from './ThumbnailGrid'

import { CARDS } from "../../Data/DataGallery";

import Filter from './Filter'

import more from '../../../images/more.png'

class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cards: CARDS,
      cardFilter: null,
      activeIndex: 0,
      id_Clicker: 0,
      showThumbnail: false,
      error: null,
      isLoaded: false,
      images: [],
      completeList: [],
      next_page_url: null,
      enabled: true,
    };
  } 

  
  fetchImages = (url) => {
    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            images: result.data,
            completeList: result.data,
            next_page_url: result.next_page_url,
            enabled: true
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  // Appel des données

  componentDidMount() {
    this.fetchImages("api/galerie")
  }

  //

  recupImageSuivante = () => {
    const { next_page_url, enabled } = this.state
    if (next_page_url !== null ) {
      fetch(next_page_url)
      .then(res => res.json())
      .then(
        (result) => {
          if (next_page_url !== null ) {
            let triImages = this.state.images.concat(result.data)
            this.setState({
              images: triImages,
              completeList: this.state.completeList.concat(result.data),
              next_page_url: result.next_page_url,
              enabled: true
            });
          }
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
    } else {
      this.setState({enabled: false})
    }
  }

  cardFilterSelect = (filterName) => {
    if (filterName !== null) {
      this.fetchImages("api/galerie/"+ filterName)
    } else if (this.state.cardFilter !== null && filterName == null) {
      this.fetchImages("api/galerie")
    }
    this.setState({ cardFilter: filterName });
  }

  toggleThumbnail = () => {  
    this.setState({ 
      showThumbnail: !this.state.showThumbnail  
  });  
  } 

  renderThumbnails = () => {
    const { error, isLoaded, images, id_Clicker, enabled } = this.state
    if (error) {
      return <div>Erreur : {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Chargement…</div>;
    } else {
      return (
        <>
          {this.state.showThumbnail && window.innerWidth > 600 ? 
              <ActiveThumbnailWindow 
                activeThumbnail={images[id_Clicker]}
                activeTitle={images[id_Clicker].titre}
                activeDescription={images[id_Clicker].description}
                closeThumbnail={this.toggleThumbnail.bind(this)}
                showThumbnail={this.state.showThumbnail}
              />
            : null
          }
          <Filter 
            cardFilterSelect={filterName => this.cardFilterSelect(filterName)}
          />
          <ThumbnailGrid 
            images={images}
            filter={this.state.cardFilter}
            handleClick={this.handleClick}
            toggleThumbnail={this.toggleThumbnail}
          />
          <button className="box-moreGallery" onClick={this.recupImageSuivante} disabled={!enabled}>
            <img className="moreGallery" src={more} alt="Plus"/>
          </button>
        </>
      )
    }
  }

  renderTextContent = () => {
    const { images, id_Clicker } = this.state

    if(images.length) {
      return (
        <>
          <h1>{images[id_Clicker].titre}</h1>
          <p>
            {images[id_Clicker].description}
          </p>
        </>
      )
    }
  }

  handleClick = (e) => {
    const newActiveIndex = e.target.getAttribute('data-index')
    this.setState({ id_Clicker: newActiveIndex})
  }

  render() {
    return (
      <Fragment>
        <div>
          { this.renderThumbnails() }
        </div>
      </Fragment>
    )
  }
}

export default Gallery