import React, {Component} from 'react';

//Images
import ImageHerbignac from '../../../images/logo_herbignac.png';
import cross from '../../../images/cross.png'

class Popup extends Component {
  render() {
    return (
      <div className='popup-overlay'>
        <div className='popup_inner animate_modal'>
          <div className="modal">
            <div className="content-about content-about_popup">
              <h2>Herbignac, Terre de potiers</h2>
              <div onClick={this.props.closePopup} className="icone-croix">
                <img src={cross} alt="Croix pour fermer le popup"/>
              </div>
              <div className="content-popup_text">
                <div className="content-historique">
                  <img src={ ImageHerbignac } alt="Chateau de Ranrouet"></img>
                  <div className="content-subtitle-popup">
                    <div className="subtitle-lineOne-popup"></div>
                    <div className="subtitle-lineTwo-popup"></div>
                    <h3>Historique du château de Ranrouët</h3>
                  </div>
                  <p className="content-text">Situé à une place stratégique, en bordure de Brière le château de Ranrouët commandait 
                  l’entrée de la presque-île de Guérande pour qui venait du nord de la Bretagne. <span className="text-supp_mobile">Il a été 
                  édifié au 13ème siècle et constamment remanié jusqu’au 17ème siècle.</span>
                  </p>
                </div>
                <div>
                  <div className="content-subtitle-popup">
                    <div className="subtitle-lineOne-popup"></div>
                    <div className="subtitle-lineTwo-popup"></div>
                    <h3>Herbignac</h3>
                  </div>
                  <p>Située en Loire-Atlantique à proximité de Guérande, dans le <span style={{ fontWeight: '700' }}>"Parc Naturel Régional de la Brière"</span>.</p>
                  <div className="text-supp_responsive" style={{ width: '80%', marginLeft: '0' }}>Période historique pour cette terre de potiers : 
                    <ul>
                      <li><span style={{ fontWeight: '700' }}>Moyen Âge : </span>Peu de renseignements subsistent, il semblerait cependant que certains fours étaient en activités.</li>
                      <li><span style={{ fontWeight: '700' }}>Période Moderne : </span>Au 17ème siècle, un conflit oppose les potiers au seigneur de Trévelec qui possède les 
                      terres où les potiers font de l’extraction. Leur production est presque exclusivement utilitaire.</li>
                    </ul>
                  </div>
                  <div className="text-supp_smMobile" style={{ width: '100%', marginLeft: '0' }}><span className="text-supp_mobile" style={{ fontWeight: '700' }}>Période contemporaine : </span><span className="text-supp_mobile">Les témoignages se font plus nombreux…</span>
                    <ul>
                      <li className="text-supp_mobile">1945 : Mort de Joseph Delino, avec lui s’éteint l’activité de poterie traditionnelle à Herbignac. </li>
                      <li className="text-supp_responsive">1970 : André Leméter s’installe à Landieul.</li>
                      <li className="text-supp_responsive">1998 : Deux ateliers « Daleu et Gaïa » s’installent sur la commune, l’un près du site du <span style={{ fontWeight: '700' }}>Château de Ranrouët </span>l’autre à Landieul.</li>
                      <li><span className="text-supp_mobile">1999 :</span> Un marché de potier voit le jour lors du week-end de l’Ascension. <span className="text-supp_mobile">8000 personnes au moins découvrent les productions 
                      d’une quarantaine de potiers venus de toutes les régions de France. Chaque année, un thème différent est retenu. Depuis ce jour, 
                      les plus grands potiers exposent tous les ans leur travail au Château de Ranrouët.</span></li>
                    </ul>
                  </div>
                </div>
                <div className="lien-ville">
                  <button><a href="http://www.herbignac.com/" target = "_blank" rel="noopener noreferrer">Site de la ville</a></button>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Popup;
