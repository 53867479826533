import React from 'react'

import ThumbnailCard from './ThumbnailCard';

const ThumbnailGrid = ( { images, handleClick, toggleThumbnail, filter } ) => {
  return (
    <div className="container">
      {
        images
        .map((image, i) => {
          return (
            <ThumbnailCard
              key={image.id}
              image={image}
              handleClick={handleClick}
              toggleThumbnail={toggleThumbnail}
              index={i}
            />
          )
        })
      }
    </div>
  )
}

export default ThumbnailGrid