import React, { Component, Fragment } from "react";

const DescriptionCard = ({listeLigne}) => {
  let maListe = listeLigne.map((ligne, index)=> 
    <p key={index} dangerouslySetInnerHTML={{__html: ligne}}></p>
  )
  return maListe
}

class AddActivite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isToggle: true
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(state => ({
      isToggle: !state.isToggle
    }));
  }
  
  render() {
    
    const activite = this.props.activites
      .map(activite => {
        return (
          (this.state.isToggle && window.innerWidth >= 1100) ? 
            <div title="Cliquer pour afficher toutes nos activités" key={activite.id} id={activite.idAncre} className="card" onClick={this.handleClick}>
              <div className="imgbox">
                <img src={activite.image} alt={activite.title}/>
              </div>
              <div className="card-title">
                <h3>{activite.title}</h3>
              </div>
              <div className="content">
                <h3>{activite.title}</h3>
                <div className="content-text-activite">
                  <div className="content-descriptionOne">
                    <div className="style-text"> 
                      <DescriptionCard listeLigne = {activite.descriptionOne}/>
                    </div>
                  </div>
        
                  <div className="line-separation-text"></div>
        
                  <div className="content-descriptionTwo">
                    <div className="style-text">
                      <DescriptionCard listeLigne = {activite.descriptionTwo}/>
                    </div>
                  </div>
        
                </div>
              </div>
            </div>
          :
            <div key={activite.id} id={activite.idAncre} className="card card-visible" onClick={this.handleClick}>
              <div className="imgbox">
                <img src={activite.image} alt={activite.title}/>
              </div>
              <div className="card-title">
                <h3>{activite.title}</h3>
              </div>
              <div className="content">
                <h3>{activite.title}</h3>
                <div className="content-text-activite">
                  <div className="content-descriptionOne">
                    <div className="style-text"> 
                      <DescriptionCard listeLigne = {activite.descriptionOne}/>
                    </div>
                  </div>
        
                  <div className="line-separation-text"></div>
        
                  <div className="content-descriptionTwo">
                    <div className="style-text">
                      <DescriptionCard listeLigne = {activite.descriptionTwo}/>
                    </div>
                  </div>
        
                </div>
              </div>
            </div>
        );
      });
 
    return (
        <Fragment>
          {activite}
        </Fragment>
    );
  }
}

export default AddActivite;




