import React from 'react';

import './SideDrawer.css'

import cross from '../../../images/cross.png'

const sideDrawer = props => {

  let drawerCLasses = ['side-drawer'];
  let crossMenu = ['close-menu'];

  if (props.show) {
    drawerCLasses = 'side-drawer open';
    crossMenu = 'close-menu-open'
  }
  return (
    <nav className={drawerCLasses}>
      <button className={crossMenu} onClick={props.click}>
        <div className="close-menu-style">
          <img src={cross} alt="Croix pour fermer le menu"/>
        </div>
      </button>
      <ul>
        <li>
          <a className="menu-items" href="/" onClick={props.click}>Accueil</a>
        </li>
        <li>
          <a className="menu-items" href="/Nos-Activites" onClick={props.click}>Nos Activités</a>
        </li>
        <li>
          <a className="menu-items" href="/Galerie" onClick={props.click}>Galerie</a>
        </li>
        <li>
          <a className="menu-items" href="#Contact" onClick={props.click}>Contact</a>
        </li>
      </ul>
    </nav>
  )
};

export default sideDrawer;