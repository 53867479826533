import React from 'react'

import './ErrorPage.css'

function ErrorPage() {
  return (
    <div className="container-errorPage">
      <div className="content-errorPage">
        <h1>Page non trouvée</h1>
        <h2>Nous avons rencontré un problème</h2>
        <button className="btn-errorPage"><a href="/">← Retour page d'accueil</a></button>
      </div>
    </div>
  )
}

export default ErrorPage;