import React, { Component, Fragment } from "react";

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'



class Calendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoaded: false,
      evenements: []
    };

    this.buttonText={today: "aujourd'hui"}
  } 
  // Appel des données

  componentDidMount() {
    fetch("api/liste-evenement")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            evenements: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  //


  render () {
    const { error, isLoaded, evenements, } = this.state
    if (error) {
      return <div>Erreur : {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Chargement…</div>;
    } else {

      return (
        <Fragment>
          <div className="container-calendar">
            <div className="content-calendar">
              <FullCalendar
                plugins={[ dayGridPlugin ]}
                initialView="dayGridMonth"
                className="calendar-relou"
                events={evenements}
                locale="fr"
                timeZone="UTC"
                firstDay="1"
                buttonText={this.buttonText}
                eventColor="#F59331"
              />
            </div>
          </div>
        </Fragment>
      );

    }
  }
  
}

export default Calendar;




