import React, { Component, Fragment } from "react";

import arrowTag from '../../../images/arrow-tag.png'

class Filter extends Component {
  constructor ( props ){
    super ( props )
    this.state = { 
      show : false,
      error: null,
      isLoaded: false,
      tags: [] 
    }; 

    this.toggleDiv = this.toggleDiv.bind(this)

  }

  //

  componentDidMount() {
    fetch("api/liste-tag")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            tags: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  //

  toggleDiv = () => {
    const { show } = this.state;
    this.setState( { show : !show } )
  }

  render() {
    const { error, isLoaded, tags } = this.state;
    let filterButtons = null;
    if (error) {
      return <div>Erreur : {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Chargement…</div>;
    } else {
    filterButtons = tags.map(filterName => {
      return (
        <div key={filterName.libelle} className="btn-list animate__backInLeft">
          <button id="en-cours"
            onClick={() => this.props.cardFilterSelect(filterName.libelle)}>
                {filterName.libelle}
          </button>
        </div>
      );
    });
  }
 
    return (
        <Fragment>
            <div className="container-tags">
              <div className="content-tags">
                <div onClick={() => this.props.cardFilterSelect(null)} className="apparition-liste">
                  <p onClick={this.toggleDiv} style={{ cursor: 'pointer',     padding: '5px 0' }}>Tout voir</p>
                  <img title="Voir la liste des tags" onClick={this.toggleDiv} className="arrowTag animation-rebond" src={arrowTag} alt="Flèche vers le bas"/>
                </div>
                  {this.state.show && filterButtons}
              </div>
            </div> 
        </Fragment>
    );
  }
}

export default Filter;