import React from 'react'

const ThumbnailCard = ({ image, handleClick, index, toggleThumbnail }) => {
  let classCSS = null
  switch (image.type_img) {
    case "1":
      classCSS = "paysage"
      break;
    case "2":
      classCSS = "portrait"
      break;
    case "3":
      classCSS = "panorama"
      break;
    case "4":
      classCSS = "big"
      break;

    default:
      classCSS = "paysage"
      break;
  }
  return (
    <div className={`animation ` + classCSS} onClick={toggleThumbnail}>
      <div className="content">
        <img 
          src={image.src_img} 
          alt={image.titre} 
          data-index={index}
          onClick={handleClick}
        />
        <div className="overlay" data-index={index} onClick={handleClick}>
          <div className="overlay-text" data-index={index} onClick={handleClick}>
              <h2 className="title" data-index={index} onClick={handleClick}>{image.titre}</h2>
              <p className="description" data-index={index} onClick={handleClick}>{image.description}</p>
          </div>
        </div>
      </div>
    </div>  
  )
}

export default ThumbnailCard