import React, {Component, Fragment} from 'react';
import '../HomePage.css';

class Footer extends Component {
    render() {


      return (
        <Fragment>
          <div className="container-footer">
            <div className="content-footer">
              <div className="text-footer">
                <p>Art-Terre</p>
                <p>Association Loi 1901 </p>
                <p>Siret 433 766 714 00011</p>
              </div>
              <div className="text-footer">
                <p>Maison des associations</p>
                <p>Place d'Argencé</p>
                <p>44410 HERBIGNAC</p>
              </div>
              <div id="Contact" className="text-footer">
                <p>Contact :</p>
                <p>06 98 66 10 50</p>
                <p>arterre44410@gmail.com</p>
              </div>
            </div>
          </div>
          <div className="create-by">
            <p>© 2020 - Toute reproduction interdite - <a href="/Mentions-Legales"><strong>Mentions légales</strong></a></p>
            <p>Site réalisé par l'agence web 720inline - <a href="https://720inline.fr/" target="_blank" rel="noopener noreferrer"><strong>www.720inline.fr</strong></a></p>
          </div>
        </Fragment>
      );
    }
}

export default Footer;
