import React from 'react'

const AddSlide = ({image, title, subtitle, lienPageAcitivite}) => {
  return (
      <div>
        <div className="overlay-carousel"></div>
        <img src={image} alt=""/>
        <div className="content-img-carousel">
          <h2>{title}</h2>
          <p>{subtitle}</p>
        </div>
        <a href={lienPageAcitivite} className="btn-activite">Voir l'activité</a>
      </div>
  )
}

export default AddSlide;