import React, { Component } from "react";

import Footer from '../HomePage/Footer/Footer'
import BackToTop from '../BackToTop/BackToTop'

import Gallery from './Gallery/Gallery'

import './GalleryPage.css';

class GalleryPage extends Component {

  render() {
      return (
        <div>

          <div className="parallax-gallery">
            <div className="overlay-gallery"></div>
          </div>
          
          <div id="galerie" className="content-galerie">
            <div className="content-title">
              <h1>NOTRE GALERIE</h1>
              <div className="style-div"></div>
              <div className="style-sub-div"></div>
            </div>
            <div className="content-galerie_text">
              <p>Collection des photos prises lors d'événements ou lors des différents ateliers que nous proposons à nos adhérents. </p>
            </div>
          </div>
          <Gallery />
          <Footer />
          <BackToTop />
        </div>
      );
    }
  }
 
export default GalleryPage;