import React, { Fragment } from 'react'

import Footer from '../HomePage/Footer/Footer'
import BackToTop from '../BackToTop/BackToTop'

import './MentionPage.css'

function MentionPage() {
  return (
    <Fragment>
      <div className="container-mentionPage">
        <div className="parallax-mentions"></div>
        <h1><span className="span-inline">Art-Terre</span> - Mentions Légales</h1>

        <div className="content-mentionPage">
          <div className="content-presentationSite content-text">
            <h2>Présentation du site.</h2>
            <p>Le Site est la propriété exclusive d'<span className="span-inline">Art-Terre</span>, association loi 1901</p>
            <ul>
              <li>Adresse : Maison des Associations - 44410 HERBIGNAC</li>
              <li>Propriétaire : <span className="span-inline">Art-Terre</span></li>
              <li>Siret : 433 766 714 00011</li>
              <li>Directeur de la publication :</li>
              <li>Conception et réalisation graphique : Agence web 720<span className = "span-inline">inline</span></li>
              <li>Développements techniques: Agence web 720<span className = "span-inline">inline</span></li>
              <li>Hébergement : Ionos by 1&amp;1 - 56410 - Montabaur - Allemagne - www.ionos.fr</li>
            </ul>
          </div>

          <div className="content-creditPhoto content-text">
            <h2>Crédits photos.</h2>
            <p>Toutes les photographies de ce site sont la propriété d'<span className="span-inline">Art-Terre</span> ou de leurs auteurs. Pour toute utilisation, merci d'en faire la demande par mail à <strong>arterre44410@gmail.com</strong>.</p>
          </div>

          <div className="content-proIntelectuelle content-text">
            <h2>Propriété intellectuelle.</h2>
            <p>Ce Site est la propriété exclusive d'<span className="span-inline">Art-Terre</span>, seul autorisé à utiliser et exploiter les droits de propriété intellectuelle de ce site. Il est interdit de copier, de représenter, de modifier, de publier ou d'adapter une partie ou tout les éléments du site, quel que soit le moyen ou le procédé utilisé. Selon les dispositions de l'article L.335-2 et les dispositions de la loi sur la propriété intellectuelle, toute utilisation non autorisée du site ou de tout contenu qu'il contient sera considéré comme une contrefaçon et sera poursuivie.</p>
          </div>

          <div className="content-conditionGenerale content-text">
            <h2>Conditions générales d’utilisation du site.</h2>
            <p>L'utilisation du site implique l'acceptation des conditions générales d'utilisation. Ces conditions d'utilisation peuvent être modifiées ou complétées à tout moment, merci de les consulter régulièrement.<br/><br/>

            Les utilisateurs peuvent visiter le site à tout moment. Toutefois, l'interruption pour raison de maintenance technique peut être décidée par l'agence web 720<span className = "span-inline">inline</span>, l'agence communiquera à l'avance la date et l'heure de l'intervention.<br/><br/>

            Le site peut être mis à jour. De la même façon, les mentions légales peuvent être modifiées: néanmoins, les utilisateurs sont invités à les relire le plus souvent possible afin d’en prendre connaissance.
            </p>
          </div>

          <div className="content-cookies content-text">
            <h2>Liens hypertextes</h2>
            <p>Le site à des liens hypertextes qui renvoie vers d'autres sites, tel que la ville d'Herbignac. <span className="span-inline">Art-Terre</span> ne peut pas vérifier le contenu du site visité, il n'est donc pas responsable des problèmes qui surviennent.</p>
          </div>

          <div className="content-responsabilite content-text">
            <h2>Responsabilité de l’Agence web 720inline.</h2>
            <p>Dans les conditions autorisées par la loi, 720<span className = "span-inline">inline</span> ne sera pas responsable des dommages causés par la modification des informations disponibles sur le site en raison de l'invasion d'un tiers.<br/><br/>

            Les visiteurs sont informés que 720<span className = "span-inline">inline</span> peut interrompre l'accès au site à tout moment en raison de maintenance ou de toute autre restriction technique. </p>
          </div>

          <div className="content-droit content-text">
            <h2>Droit applicable de juridiction.</h2>
            <p>Les juridictions françaises sont seules compétentes pour connaître de tout litige relatif à l'utilisation de ce site Internet, régi par le droit français.</p>
          </div>

        </div>
      </div>
      <Footer />
      <BackToTop />
    </Fragment>
  )
}

export default MentionPage;