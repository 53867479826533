import React, { Fragment } from 'react'

import './DrawerToggleButton.css';


const drawerToggleButton = props => (
  <Fragment>
    <button className="toggle-button" onClick={props.click}>
      <div className="toggle-button_line "/>
      <div className="toggle-button_line"/>
      <div className="toggle-button_line"/>
      <p>Menu</p>
    </button>
  </Fragment>
);

export default drawerToggleButton;