import React, {Fragment} from 'react'
import '../HomePage.css';


const Header = () => {
  return (
    <Fragment>
      <div className="parallax-header">
        <div className="overlay-header"></div>
        <div className="content-header">

          <div className="content-bloc-name">
            <div className="lineOne fadeInUp-lineOne"></div>
            <div className="lineTwo fadeInUp-lineTwo"></div>
            <h1 className="fadeIn-name">Atelier Associatif <span className="size-name">Art-Terre</span></h1>
          </div>

          <div className="content-bloc-subtitle">
            <div className="align-bloc-subtitleOne">
              <p className="fadeIn-subtitle">Poterie</p>
              <div className="line_subtitleOne fadeInLeft-subtitle"></div>
            </div>
            <div className="align-bloc-subtitleTwo">
              <p className="fadeIn-subtitle">Modelage</p>
            </div>
            <div className="align-bloc-subtitleThree">
              <div className="line_subtitleTwo fadeInRight-subtitle"></div>
              <p className="fadeIn-subtitle">Sculpture</p>
            </div>
          </div>
            
        </div>
      </div>
    </Fragment>
  )
}

export default Header;

/*

*/