import React, { Component } from 'react';
import AddSlide from './AddSlide';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ImageActivites1 from '../../../images/act1.jpg';
import ImageActivites2 from '../../../images/act2.jpg';
import ImageActivites3 from '../../../images/act3.jpg';
import ImageActivites4 from '../../../images/act4.jpg';
import ImageActivites5 from '../../../images/act5.jpg';

const newSlide = {
  slide1: {
    image: ImageActivites1,
    title: 'ADHESION',
    subtitle: 'Pour les grands et les petits',
    lienPageAcitivite: '/Nos-Activites#adhesion',
  },
  slide2: {
    image: ImageActivites2,
    title: 'ATELIERS ENFANTS',
    subtitle: 'A partir de 6 ans',
    lienPageAcitivite: '/Nos-Activites#enfants',
  },
  slide3: {
    image: ImageActivites3,
    title: 'ATELIERS ADULTES',
    subtitle: 'A partir de 18 ans',
    lienPageAcitivite: '/Nos-Activites#adultes',
  },
  slide4: {
    image: ImageActivites4,
    title: 'STAGE VACANCES',
    subtitle: 'A partir de 6 ans',
    lienPageAcitivite: '/Nos-Activites#stageVacances',
  },
  slide5: {
    image: ImageActivites5,
    title: 'EVENEMENTS',
    subtitle: 'Adhérents',
    lienPageAcitivite: '/Nos-Activites#evenements',
  }
}
 
class Slide extends Component {
    state = {
      newSlide
    }
    render() {
      const { newSlide } = this.state

      const listeSlide = Object.keys(newSlide)
      .map(slide => (
        <AddSlide
        key={slide}
        image = {newSlide[slide].image}
        title = {newSlide[slide].title}
        subtitle = {newSlide[slide].subtitle}
        lienPageAcitivite = {newSlide[slide].lienPageAcitivite}/>
      ))
        return (
            <Carousel 
            infiniteLoop 
            autoPlay 
            showThumbs={false} 
            showStatus={false} 
            transitionTime={1350} 
            interval={6000}
            stopOnHover={true}
            >
              {listeSlide}
            </Carousel>
        );
    }
};

export default Slide;