import img1 from '../../images/img-gallery/img1.jpg'
import img2 from '../../images/img-gallery/img2.jpg'
import img3 from '../../images/img-gallery/img3.jpg'
import img4 from '../../images/img-gallery/img4.jpg'
import img5 from '../../images/img-gallery/img5.jpg'
import img6 from '../../images/img-gallery/img6.jpg'
import img7 from '../../images/img-gallery/img7.jpg'
import img8 from '../../images/img-gallery/img8.jpg'
import img9 from '../../images/img-gallery/img9.jpg'
import img10 from '../../images/img-gallery/img10.jpg'
import img11 from '../../images/img-gallery/img11.jpg'
import img12 from '../../images/img-gallery/img12.jpg'
import img13 from '../../images/img-gallery/img13.jpg'
import img14 from '../../images/img-gallery/img14.jpg'
import img15 from '../../images/img-gallery/img15.jpg'
import img16 from '../../images/img-gallery/img16.jpg'
import img17 from '../../images/img-gallery/img17.jpg'
import img18 from '../../images/img-gallery/img18.jpg'
import img19 from '../../images/img-gallery/img19.jpg'
import img20 from '../../images/img-gallery/img20.jpg'
import img21 from '../../images/img-gallery/img21.jpg'
import img22 from '../../images/img-gallery/img22.jpg'
import img23 from '../../images/img-gallery/img23.jpg'
import img24 from '../../images/img-gallery/img24.jpg'
import img25 from '../../images/img-gallery/img25.jpg'
import img26 from '../../images/img-gallery/img26.jpg'
import img27 from '../../images/img-gallery/img27.jpg'
import img28 from '../../images/img-gallery/img28.jpg'
import img29 from '../../images/img-gallery/img29.jpg'
import img30 from '../../images/img-gallery/img30.jpg'
import img31 from '../../images/img-gallery/img31.jpg'
import img32 from '../../images/img-gallery/img32.jpg'

export const CARDS = [
  {
    id: 1,
    image: img1,
    title: "Herbignac",
    description: "Lorem",
    type: "Modelage",
    style: 'paysage'
  },
  {
    id: 2,
    image: img2,
    title: "Terre de potiers",
    description: "nulla.",
    type: "Tournage",
    style: 'paysage'
  },
  {
    id: 3,
    image: img3,
    title: "Nantes",
    description: "Lorem ipsum dolor sit,",
    type: "Modelage",
    style: 'vertical'
  },
  {
    id: 4,
    image: img4,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Autres",
    style: 'vertical'
  },
  {
    id: 5,
    image: img5,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Modelage",
    style: 'paysage'
  },
  {
    id: 6,
    image: img6,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Plaque",
    style: 'paysage'
  },
  {
    id: 7,
    image: img7,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Tournage",
    style: 'big'
  },
  {
    id: 8,
    image: img8,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Modelage",
    style: 'vertical'
  },
  {
    id: 9,
    image: img9,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Modelage",
    style: 'vertical'
  },
  {
    id: 10,
    image: img10,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Plaque",
    style: 'paysage'
  },
  {
    id: 11,
    image: img11,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Plaque",
    style: 'vertical'
  },
  {
    id: 12,
    image: img12,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Plaque",
    style: 'big'
  },
  {
    id: 13,
    image: img13,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Raku",
    style: 'vertical'
  },
  {
    id: 14,
    image: img14,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Différents stades d'une pièce",
    style: 'vertical'
  },
  {
    id: 15,
    image: img15,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Raku",
    style: 'paysage'
  },
  {
    id: 16,
    image: img16,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Réalisations enfants",
    style: 'vertical'
  },
  {
    id: 17,
    image: img17,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Raku",
    style: 'vertical'
  },
  {
    id: 18,
    image: img18,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Réalisations enfants",
    style: 'paysage'
  },
  {
    id: 19,
    image: img19,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Différents stades d'une pièce",
    style: 'vertical'
  },
  {
    id: 20,
    image: img20,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Raku",
    style: 'vertical'
  },
  {
    id: 21,
    image: img21,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Modelage",
    style: 'vertical'
  },
  {
    id: 22,
    image: img22,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Réalisations enfants",
    style: 'paysage'
  },
  {
    id: 23,
    image: img23,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Réalisations stages",
    style: 'horizontal'
  },
  {
    id: 24,
    image: img24,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Réalisations stages",
    style: 'big'
  },
  {
    id: 25,
    image: img25,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Modelage",
    style: 'vertical'
  },
  {
    id: 26,
    image: img26,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Différents stades d'une pièce",
    style: 'paysage'
  },
  {
    id: 27,
    image: img27,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Modelage",
    style: 'paysage'
  },
  {
    id: 28,
    image: img28,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Réalisations stages",
    style: 'vertical'
  },
  {
    id: 29,
    image: img29,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Modelage",
    style: 'big'
  },
  {
    id: 30,
    image: img30,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Autres",
    style: 'paysage'
  },
  {
    id: 31,
    image: img31,
    title: "Herbignac, Terre de potiers",
    description: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Adipisci, impedit culpa, sapiente libero tempora eligendi animi architecto corporis, ratione quia nulla.",
    type: "Différents stades d'une pièce",
    style: 'vertical'
  },
  {
    id: 32,
    image: img32,
    title: "Test",
    description: "Lorem",
    type: "Modelage",
    style: 'paysage'
  }
];