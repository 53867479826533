import React, {Component} from 'react';
import '../HomePage.css';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

import Slide from './Slide'

class Carousel extends Component {
    render() {


      return (
        <ScrollAnimation animateIn="animate__animated animate__fadeInRightBig" animateOnce={true}>
           <div className="container-body">
            <div className="bloc-carousel">
              <div className="bloc-carousel__img">
                <Slide />
              </div>
              <div className="content-carousel">
                <div className="content-title-carousel">
                  <div className="title-lineOne-carousel"></div>
                  <div className="title-lineTwo-carousel"></div>
                  <h2>Nos Activités</h2>
                  <div className="title-lineTwo-carousel"></div>
                  <div className="title-lineOne-carousel"></div>
                </div>
                <div className="content-carousel_text">
                  <p>Art-Terre propose des ateliers collectifs de découverte et de partage des expériences basés sur la libre créativité pour les grands et les petits.</p>
                </div>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      );
    }
}

export default Carousel;
