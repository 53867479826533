import React, {Component, Fragment} from 'react';
import './Activite.css';

import { ACTIVITES } from "../Data/DataActivite";

import Footer from '../HomePage/Footer/Footer';
import AddActivite from './AddActivite';
import Calendar from './Calendar';

import BackToTop from '../BackToTop/BackToTop'

class Activite extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      activites: ACTIVITES
    };
  }
 
  onChange = date => this.setState({ date })

    render() {
      
      return (
        <Fragment>
          <div className="parallax-activite">
            <div className="overlay-activite"></div>
          </div>

          <div id="activite" className="content-activites">
            <div className="content-title">
              <h1>NOS ACTIVITES</h1>
              <div className="style-div"></div>
              <div className="style-sub-div"></div>
            </div>
            <div className="content-activites_text">
              <p>Art-Terre propose des ateliers collectifs de découverte et de partage des expériences basés sur la libre créativité pour les grands et les petits.</p>
            </div>
          </div>

          <div className="body-card">
            <div className="container-card">
              <AddActivite activites={this.state.activites}/>
              <Calendar />
            </div>
            
          </div>

          <Footer />
          
          <BackToTop />
        </Fragment>
      );
    }
}

export default Activite;
