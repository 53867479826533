//image card
import img1 from '../../images/act1.jpg';
import img2 from '../../images/act2.jpg';
import img3 from '../../images/act3.jpg';
import img4 from '../../images/act4.jpg';
import img5 from '../../images/act5.jpg';

export const ACTIVITES = [
  {
    id: 1,
    image: img1,
    title: 'Adhésion',
    descriptionOne: ['<span style="font-weight:600;">ADULTES</span>', 'Adhésion annuelle  15 €', 'Cotisation annuelle	85 €',  'Pièce biscuitée  4 € / kg', 'Pièce biscuités et émaillée	 5 € / kg'],
    descriptionTwo: ['<span style="font-weight:600;">ENFANTS</span>', 'Mercredi de 9h45 à 11h15', 'Adhésion annuelle (Herbignac)  10 €', 'Adhésion annuelle (hors Herbignac)  15 €', 'Fournitures à l’année  10 €', 'Cotisation par trimestre  50 €'],
    idAncre: 'adhesion',
  },
  {
    id: 2,
    image: img2,
    title: 'Enfants',
    descriptionOne: ['L’atelier <span style="font-weight:600;">« enfants »</span> est ouvert le mercredi matin de 9h45 à 11h15, hors vacances scolaires, pour un groupe de <span style="font-weight:600;">6 enfants maximum</span>. Il est destiné aux enfants de 6 ans et plus. Le groupe est encadré par l’animatrice de l’association.'],
    descriptionTwo: ['Les cours sont dirigés et orientés vers toutes les techniques de la céramique. Ils permettent aux enfants de progresser et  <span style="font-weight:600;">développer leur créativité</span>.'],
    idAncre: 'enfants',
  },
  {
    id: 3,
    image: img3,
    title: 'Adultes',
    descriptionOne: ['<span style="font-weight:600;">ART-TERRE</span> propose des ateliers collectifs de découverte et de partage des expériences basés sur la libre créativité. <span style="font-weight:600;">L\'innovation, l\'ouverture de l\'association vers l\'extérieur et l\'esprit jovial y sont essentiels</span>.', 'Toutes les techniques sont abordées : modelage, estampage, colombins, plaques, tour, émaillage, cuisson électrique ou raku.'],
    descriptionTwo: ['L\'atelier ouvert toute l\'année:', 'Lundi au Samedi : 10h00-18h00', 'Sauf Mercredi et Vendredi : 14h00-18h00', 'Sauf stages vacances scolaires (jours à définir à la demande)', 'Les adhérents sont encadrés le vendredi après-midi par l’animatrice.'],
    idAncre: 'adultes',
  },
  {
    id: 4,
    image: img4,
    title: 'Stage Vacances',
    descriptionOne: ['Les stages se déroulent sur 2 jours 10h00-12h00', 'Les jours sont fixés en fonction des demandes et des vacances.', '<span style="font-weight:600;">RÉSERVATION OBLIGATOIRE</span>.'],
    descriptionTwo: ['La terre et le matériel sont mis gratuitement à disposition des enfants. Toutes les techniques sont dispensées. Le sujet est libre.', 'Adhésion ponctuelle Herbignac 3€', 'Adhésion ponctuelle hors Herbignac 5€', 'Coût du stage 30€'],
    idAncre: 'stageVacances',
  },
  {
    id: 5,
    image: img5,
    title: 'Calendrier de nos évènements',
    descriptionOne: ['La vocation d’<span style="font-weight:600;">ART-TERRE</span> est aussi de participer aux manifestations locales telles que le traditionnel <span style="font-weight:600;">marché des potiers</span>, mais aussi les <span style="font-weight:600;">journées du patrimoine</span> et quelques expositions qui permettent de faire connaître l\'association.'],
    descriptionTwo: ['Au cours de l’année, différents <span style="font-weight:600;">stages animés par des professionnels</span> sont prévus à l’atelier. Ils sont orientés suivant les choix des adhérents.'],
    idAncre: 'evenements',
  }
];