import React from 'react'

import './Toolbar.css'

// Import et Création icone humburger 
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton'

const toolbar = props => {

  let showicon = ['toolbar_navigation'];

  if (props.show) {
    showicon = 'toolbar_navigation open_sidedrawer';
  }
  
  return (
    <header className="toolbar">
      <h1><a href="/">Art-Terre</a></h1>
      <nav id={props.animationNav()} className={showicon}>
        <div className="toolbar__toggle-button">
          <DrawerToggleButton click={props.drawerClickHandler}/>
        </div>
        <div className="spacer" />
      </nav>
    </header>
  )
};

export default toolbar; 