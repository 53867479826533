import React, {Component, Fragment} from 'react';
import '../HomePage.css';
import Popup from './Popup'
import Image from '../../../images/img_about.jpg';

import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

class About extends Component {

  constructor(props){  
    super(props);  
    this.state = { showPopup: false };  
    }  
    
      togglePopup() {  
    this.setState({  
         showPopup: !this.state.showPopup  
    });  
     }  

    render() {


      return (
        <Fragment>
          <ScrollAnimation animateIn="animate__animated animate__fadeInLeftBig" animateOnce={true} className="bloc-info_about">
            <div className="bloc-info-about__img">
              <img src={ Image } alt=""/>
              <button className="btn-popup" onClick={this.togglePopup.bind(this)}>En savoir plus sur l'histoire de la ville.</button>
            </div>
            <div className="content-about">
              <h2>A propos de l'association</h2>
              <span className="style-span-about"></span>
              <div className="content-about_text">
                <p>Elle fut créée en 1995. L'association se trouve sur le site du <span className="span-bold">Château de
                Ranrouët</span> à Herbignac en Loire-Atlantique. Son but est de promouvoir 
                les arts liés à la terre tel que la <span className="span-bold">poterie</span> et la <span className="span-bold">céramique</span>.</p><br></br>

                <p><span className="span-bold">Art-Terre</span> propose de vous faire découvrir le plaisir des arts 
                de la Terre, dans un espace <span className="span-bold">convivial</span>, de <span className="span-bold">rencontre</span> et d’<span className="span-bold">expression</span>, 
                ouvert à tous : petits (à partir de 6 ans) et grands.</p><br></br>

                <div>Elle permet aux adhérents :
                  <ul>
                    <li>Le développement de la créativité.</li>
                    <li>L' apprentissage de la matière et la découverte des différentes cuissons.</li>
                    <li>La découverte des engobes et des émaux.</li>
                  </ul>
                </div>
              </div>
            </div>
          </ScrollAnimation>
          {this.state.showPopup ? 
            <Popup
              closePopup={this.togglePopup.bind(this)}
            />
            : null
          }
        </Fragment>
      );
    }
}

export default About;
