import React from 'react'

//Images
import cross from '../../../images/cross.png'

const ActiveThumbnailWindow = ({ showThumbnail, closeThumbnail, activeThumbnail, activeTitle, activeDescription }) => {
    let modalImgClass = ['full-img'];
  
    if (showThumbnail) {
      modalImgClass = 'full-img animate_modal-gallery';
    }
  
    return (
      <div className="modal-gallery">
        <div className="overlay-modal-gallery" onClick={closeThumbnail}/>
        <div onClick={closeThumbnail} className="icone-croix-thumbnail">
          <img src={cross} alt="Croix pour fermer le popup"/>
        </div>
        <img src={activeThumbnail.src_img}  className={modalImgClass} alt=""/>
        <div className="content-text-img">
          <p className="caption">{activeTitle}</p>
          <p>{activeDescription}</p>
        </div>
      </div>
    );
}

export default ActiveThumbnailWindow