import React from 'react';
import './App.css';


//Components
import Menu from './components/Menu/Menu';

//Lien page
import HomePage from './components/HomePage/HomePage';
import Activite from './components/ActivitePage/Activite';
import Galerie from './components/GalleryPage/GalleryPage';

import MentionPage from './components/MentionPage/MentionPage';

//Lien Error Page
import ErrorPage from './components/ErrorPage/ErrorPage';


import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';


function App() {
  return (
    <Router>
      <Menu />

      <Switch>
        <Route exact path="/" component={HomePage}/>
        <Route path="/Nos-Activites" component={Activite}/>
        <Route path="/Galerie" component={Galerie}/>
        <Route path="/Mentions-Legales" component={MentionPage}/>
        <Route component={ErrorPage}/> 
      </Switch>
    </Router>
  );
}

export default App;
