import React, { Component, Fragment } from 'react'

//Components 
import Header from './Header/Header'
import About from './About/About'
import Carousel from './Carousel/Carousel'
import Adhesion from './Adhesion/Adhesion'
import Footer from './Footer/Footer'

import BackToTop from '../BackToTop/BackToTop'

class HomePage extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <About />
        <Carousel />
        <Adhesion />
        <Footer />

        <BackToTop />
      </Fragment>
    )
  }
}

export default HomePage;

