import React, { Component, Fragment } from 'react';

//Components Menu

//Barre d'outils du Menu affichant l'icone "humburger"
import Toolbar from './Toolbar/Toolbar';

//Menu avec les différents liens pour la page
import SideDrawer from './SideDrawer/SideDrawer';

//Overlay noir transparent sur toute la page
import OverlayMenu from './OverlayMenu/OverlayMenu';


class Menu extends Component {
  state = {
    sideDrawerOpen: false
  };

  //Affiche le Menu onClick
  drawerToogleClickHandler = () => {
    this.setState((prevState) => {
      return {sideDrawerOpen: !prevState.sideDrawerOpen};
    });
  };

  //Fermer le Menu en cliquant sur l'overlay (n'importe ou sur la page)
  overlayMenuClickHandler = () => {
    this.setState({sideDrawerOpen: false});
  };

  render() {

    let overlayMenu;

    if (this.state.sideDrawerOpen) {
      overlayMenu = <OverlayMenu click={this.overlayMenuClickHandler}/>;
    }
    return (
      <Fragment>
        <Toolbar show={this.state.sideDrawerOpen} drawerClickHandler={this.drawerToogleClickHandler} animationNav={() => {return "nav"}}/>
        <SideDrawer show={this.state.sideDrawerOpen} click={this.overlayMenuClickHandler}/>
        {overlayMenu}
      </Fragment>
    );
  }
}

export default Menu;
