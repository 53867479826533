import React, {Component} from 'react';
import '../HomePage.css';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

import brochureAdhesion from '../../../pdf/brochure-adhesion.pdf'

class Adhesion extends Component {
    render() {


      return (
      <ScrollAnimation animateIn="animate__animated animate__fadeInLeftBig" animateOnce={true}>
        <div className="bloc-adhesion">
          <div className="overlay-adhesion"/>
          <div className="content-adhesion">
            <div className="content-title-adhesion">
              <div className="title-lineOne-adhesion"></div>
              <div className="title-lineTwo-adhesion"></div>
                <h2>Adhésion</h2>
              <div className="title-lineTwo-adhesion"></div>
              <div className="title-lineOne-adhesion"></div>
            </div>
            <div className="content-adhesion_text">
              <div className="content-paragraph">
                <p>Une adhésion annuelle pour les ateliers ou ponctuelle pour les stages est obligatoire.</p>
              </div>
              <div className="content-paragraph">
                <p>Être membre de l'association c'est participer à son fonctionnement.</p>
              </div>
            </div>
              <a href={brochureAdhesion} target="_blank" rel="noopener noreferrer" title="Télécharger notre brochure d'adhésion" className="btn-info">Informations</a>
          </div>
        </div>
      </ScrollAnimation>
      );
    }
}

export default Adhesion;
